'use client';

import { cn } from '~/lib/utils';

function getRandomBackgroundColor(teamName: string): string {
  const colors = [
    'bg-red-500',
    'bg-blue-500',
    'bg-yellow-500',
    'bg-purple-500',
    'bg-pink-500',
    'bg-teal-500',
    'bg-orange-500',
  ];
  const defaultColor = 'bg-gray-800'; // Fallback color

  if (colors.length === 0) {
    return defaultColor;
  }

  const hash = Array.from(teamName).reduce(
    (acc, char) => acc + char.charCodeAt(0),
    0,
  );

  return colors[hash % colors.length] || defaultColor;
}

export const Avatar = ({
  name,
  classNames,
}: {
  name: string | undefined;
  classNames?: string;
}) => {
  return (
    <div
      className={cn(
        getRandomBackgroundColor(name ?? ''),
        'flex aspect-square h-8 w-8 items-center justify-center rounded-[80px] border-2 border-white text-base text-white',
        classNames,
      )}
    >
      {name}
    </div>
  );
};

export default Avatar;
